import {getDocumentById, setLocale} from 'lib/sanity'
import type {GetStaticProps} from 'next'
import {clsx} from '@local/utils'

const Products = (props: any) => {
	return (
		<section className='min-h-screen grid place-items-center'>
			404 not found
		</section>
	)
}

export const getStaticProps: GetStaticProps = async ({locale, defaultLocale, locales, preview = false}) => {
	setLocale(locale, defaultLocale, locales)

	const props = await getDocumentById('error', preview, '...')

	return {
		props: {
			...props,
			preview,
		},
	}
}

export default Products
